$link-color: hsl(225, 4%, 41%);
$active-text: #17c0e6;
$primary-color: hsl(212, 48%, 13%);
$secondary-color: hsl(183, 86%, 29%);
$default-transition: 500ms ease;
$danger-color: #f05656;
$success-color: #43a53b;
$background-green: #04ab74;

$info-color: hsl(191, 62%, 50%);
$warning-color: hsl(36, 62%, 50%);
$light-color: hsl(240, 2%, 73%);
$border-color: #b9b9bc;
$default-transition: 500ms ease;
$shadow-color: hsla(0, 0%, 0%, 0.25);
$shadow-border: rgba(17, 32, 49, 0.1);
$default-shadow: 0px 4px 4px #{$shadow-color};
$container-background: #e5e5e5;

$dashboard-radius: 50px;

$default-radius: 10px;
$chat-icon-size: 48px;
$card-padding: 15px;
$scroll-bar-width: 7px;
$grid-gap: 20px;
$card-radius: 20px;
$chat-padding: $card-padding * 0.5;

$table-padding-x: 15px;
$table-padding-y: 10px;
$table-padding: $table-padding-y $table-padding-x;

$screen-breakpoint: 1750px;
$sm-screen-card-padding: 10px 10px;

:export {
  linkColor: $link-color;
  activeText: $active-text;
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  defaultTransition: $default-transition;
  dangerColor: $danger-color;
  successColor: $success-color;
  warningColor: $warning-color;
  chatIconSize: $chat-icon-size;
  cardPadding: $card-padding;
  scrollBarWidth: $scroll-bar-width;
  gridGap: $grid-gap;
  borderColor: $border-color;

  cardRadius: $card-radius;
  chatPadding: $chat-padding;

  screenBreakpoint: $screen-breakpoint;
}

.loading {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  .logo {
    width: 100px;
    animation: breath 1s linear infinite alternate;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}

@keyframes breath {
  from {
    filter: grayscale(0);
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
    filter: grayscale(100);
  }
}

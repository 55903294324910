@import "scss/variables.scss";

$color: #b9b9bc;

.usermodal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $grid-gap;
  padding-bottom: 52px !important;
  .informations {
    display: flex;
    align-items: center;
    flex-direction: column;
    .name {
      height: 30px;
      font-family: "Dosis";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: $color;
    }
    .email {
      font-family: "Dosis";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      text-decoration-line: underline;
      color: $color;
    }
  }

  fieldset {
    display: flex;
    flex-direction: column;
    border: 0;
    width: 100%;
    gap: 10px;
    legend {
      font-family: "Ropa Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: $color;
    }
  }
}

@import "scss/variables.scss";

.authCard {
  width: 772px;
  padding: 43px 47px 155px;
  background: #ebeaef;
  border-radius: 58px;
  box-shadow: 0px 0px 20px 0px rgba(black, 0.4);
  position: relative;

  &.dark {
    color: #ebeaef;
    background: $primary-color;
  }
  @media screen and (max-width: 772px) {
    box-shadow: none;
    border-radius: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  .logo {
    width: 121px;
    height: 121px;
    margin: 0px auto 67px;
  }
}

.notfoundTitle {
  text-align: center;
  margin-bottom: 32px;
}

.notfoundLink {
  text-align: center;
  display: block;
  text-decoration: none;
  color: $link-color;
  transition: $default-transition;
  &:hover {
    color: $active-text;
    svg {
      margin-inline-end: 16px;
    }
  }
  svg {
    color: $link-color;
    transition: $default-transition;
    margin-inline-end: 8px;
  }
}

.authContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

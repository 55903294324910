@import "scss/variables.scss";

.dashboardContainer {
  background: $primary-color;
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: 100vh;
  &.closed {
    grid-template-columns: 100px auto;

    @media screen and(max-width: $screen-breakpoint) {
      grid-template-columns: 80px auto;
    }
  }
}

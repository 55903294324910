@import "scss/variables.scss";

$notif-size: 12px;

.chatOuter {
  padding: $grid-gap;
  display: flex;
  padding-left: 0px;
  height: 100%;
}

.chatContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: $chat-padding;

  .actions {
    margin-bottom: $chat-padding;
  }

  .chatBullsContainer {
    width: 100%;
    padding: 0px $chat-padding $chat-padding;
    margin: $card-radius 0px 0px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: $chat-padding;
  }
}

.spacer {
  background: transparent;
  height: 0px;
  width: $chat-icon-size;
}

@import "scss/variables.scss";
@import "scss/mixin";

$sizes: (
  "x-sm": 30px,
  "sm": 42px,
  "md": 64px,
  "lg": 90px,
);

.avatar {
  isolation: isolate;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 0;

  @each $size, $width in $sizes {
    &.#{$size} {
      width: $width;
      height: $width;
      img {
        width: $width;
        height: $width;
      }
    }
  }

  img {
    object-fit: cover;
  }
  &.loading::after {
    z-index: 1;
    position: absolute;
    @include has-loading-background;
    content: " ";
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }
}

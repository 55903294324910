@import "scss/variables.scss";

.inputField {
  display: flex;
  overflow: hidden;
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid transparent;
  align-items: center;
  transition: $default-transition;

  &:focus-within {
    outline: 2px solid $primary-color;
  }

  &.error {
    border: 2px solid $danger-color !important;
    color: $danger-color;
    input {
      &::placeholder {
        color: lighten($danger-color, 10);
      }
    }
    & > div {
      color: $danger-color;
    }
  }

  input,
  textarea {
    width: 100%;
    align-self: stretch;
    padding: 18px;
    border: 0px;
    outline: 0px;
    &::placeholder {
      color: #4d4d4d;
    }
  }
  select {
    width: 100%;
    height: 100%;
    outline: 0px;
    padding: 18px;
    border: 0px;
    background: transparent;
  }
  & > div {
    color: rgba(15, 45, 82, 0.45);
    padding: 18px;
  }
  button {
    border: none;
    background: transparent;
  }
}

@import "scss/variables.scss";
$threshold: 20%;

$text: "text";

$varients: (
  "text": white,
  "primary": $primary-color,
  "secondary": $secondary-color,
  "danger": $danger-color,
  "success": $success-color,
);

.btn {
  font-weight: 400;
  font-size: 18px;
  border: none;
  text-align: center;
  transition: background $default-transition;
  cursor: pointer;
  border-radius: $default-radius;
  font-family: "Ropa Sans", sans-serif;
  line-height: 1em;
  display: inline-block;
  white-space: nowrap;

  @media screen and(max-width: $screen-breakpoint) {
    font-size: 14px;
    padding: 6px 1em;
  }

  text-decoration: none;
  &.w100 {
    width: 100%;
  }
  &.sm {
    padding: 6px 2em;
    font-size: 15px;
  }

  &.md {
    padding: 12px 2em;
  }

  &.lg {
    padding: 22px 4em;
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.isLoading {
    cursor: wait !important;
  }

  &.circle {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    padding: 0px;
    justify-content: center;
    font-size: 20px;
  }

  @each $name, $color in $varients {
    &.#{$name} {
      color: white !important;
      background: $color;
      &:hover {
        background: if(lightness($color) < $threshold, lighten($color, 10), darken($color, 10));
      }
      &:focus,
      &:active {
        background: if(lightness($color) < $threshold, lighten($color, 10), darken($color, 10));
        outline: 3px solid rgba($color, 0.8);
      }
      &:disabled {
        color: if(lightness(lighten($color, 20)) < 50%, white, black);
        background: lighten($color, 20);
        cursor: not-allowed;
        font-weight: 400;
      }
    }
  }

  &.#{$text} {
    color: $primary-color !important;
  }
}

@import "scss/variables.scss";

$search-color: #ebeaef;

.navbar {
  display: flex;
  justify-content: space-between;

  @media screen and(max-width: $screen-breakpoint) {
    padding-left: 5px;
  }

  .breadCrumbs {
    a {
      font-family: "Dosis", sans-serif;
      font-size: 20px;
      text-decoration: none;
      color: $primary-color;
      text-transform: capitalize;
      border-bottom: 1px solid transparent;
      position: relative;
      margin-inline-end: 25px;
      border-bottom: 2px solid transparent;

      &::after {
        content: ">";
        margin: 0px 8px;
        position: absolute;
      }

      &:hover {
        border-bottom-color: currentColor;
      }

      &:last-of-type {
        color: $secondary-color;
        &::after {
          content: "";
          margin: 0px 8px;
        }
      }
    }
  }

  .left {
    display: flex;
    gap: 20px;

    .search {
      display: flex;
      align-items: center;

      position: relative;
      input {
        transition: $default-transition;
        background: $search-color;
        border: 2px solid $search-color;
        border-radius: 999999px;
        font-family: "Dosis", sans-serif;
        max-width: 500px;
        width: 300px;
        padding: 3px 20px;
        color: darken($search-color, 50);

        &::placeholder {
          color: darken($search-color, 20);
        }
        &:active,
        &:focus,
        &:focus-visible {
          border: 2px solid darken($search-color, 50);
          background: white;
          width: 500px;
          outline: none;
        }
      }

      svg {
        color: darken($search-color, 20);
        height: 20px;
        position: absolute;
        right: 8px;
      }
    }
    button {
      background: transparent;
      color: #666666;
      font-size: 20px;
      border: none;
      cursor: pointer;

      &.hasStatus {
        position: relative;
        &::after {
          position: absolute;
          content: " ";
          width: 10px;
          height: 10px;
          background: #666666;
          border-radius: 5px;
          top: 5px;
          right: 0px;
        }

        &.animated {
          svg {
            animation: ringBell 300ms ease-in-out infinite alternate;
          }
        }
        &.important::after {
          background: $danger-color;
          color: $danger-color;
        }
        &.warning::after {
          background: $warning-color;
          color: $warning-color;
        }
        &.success::after {
          background: $success-color;
          color: $success-color;
        }
      }
    }
  }
}

@keyframes ringBell {
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}

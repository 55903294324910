@import "scss/mixin.scss";

.container {
  &.small {
    margin-bottom: 9px;

    .progress_container {
      height: 9px;
      .progress {
        height: 9px;
      }
    }
  }

  margin-bottom: 15px;

  &.loading {
    .header {
      .title {
        height: 1em;
        width: 20%;
        @include has-loading-background;
      }

      .count {
        height: 1em;
        width: 20%;
        @include has-loading-background;
      }
    }

    .progress_container {
      .progress {
        @include has-loading-background;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    font-family: DM Sans;
    font-size: 16px;
    margin-bottom: 3px;
    .title {
      color: #30475e;
    }

    .count {
      color: #112031;
    }
  }

  .progress_container {
    background: rgba(0, 173, 181, 0.26);
    border-radius: 99999999px;

    height: 13px;
    overflow: hidden;
    .progress {
      border-radius: 99999999px;
      height: 13px;
      background: rgba(0, 153, 255, 1);
    }
  }
}

@import "variables";

* {
  &::-webkit-scrollbar {
    transition: $default-transition;
    width: $scroll-bar-width;
    height: $scroll-bar-width;
    background: rgba($primary-color, 0.2);
  }

  &::-webkit-scrollbar-track {
    transition: $default-transition;
    background: #f1f1f1;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba($primary-color, 0.65);
    }
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $scroll-bar-width * 0.5;
    background: rgba($primary-color, 0.2);
    transition: $default-transition;
    &:hover {
      background: $primary-color;
    }
  }
}

@import "scss/variables.scss";
.container {
  display: flex;
  background: white;
  width: 100%;
  padding: 13px;
  border-radius: $default-radius;
  justify-content: space-between;
  color: #30475e;
  .label {
    font-family: "Dosis";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #30475e;
  }

  .body {
    width: 100%;
    input,
    select {
      width: 100%;
      border: 0px;
      background: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  .action {
    margin-inline-start: 13px;
    button {
      border: none;
      background: transparent;
      color: #b9b9bc;
    }

    label {
      color: #b9b9bc;
    }
  }
}

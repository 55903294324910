@import "scss/variables.scss";

$chat-card-width: $chat-icon-size + 2 * $chat-padding + $grid-gap;

.main {
  background: #ffffff;
  border-radius: $dashboard-radius 0px 0px $dashboard-radius;
  padding: $grid-gap * 2;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: $grid-gap * 2;

  @media screen and (max-width: $screen-breakpoint) {
    border-radius: 28px 0px 0px 30px;
    padding: 20px 15px;
    gap: 10px;
  }

  .container {
    padding: 0px;
    background: $container-background;
    height: 100%;
    overflow: auto;
    border-radius: 20px;
    display: grid;
    grid-template-columns: auto;
    &.chat {
      grid-template-columns: auto $chat-card-width;
    }
    grid-template-rows: 100%;
    gap: $grid-gap * 0.5;

    @media screen and(max-width: $screen-breakpoint) {
      gap: 15px;
    }

    .innerContainer {
      padding: $grid-gap;
      height: 100%;
      overflow: auto;

      &.noPadding {
        padding: 0px;
      }
    }
  }
}

$loading-color-1: #e4e4e4;
$loading-color-2: #acacac;
$loading-background: linear-gradient(-45deg, $loading-color-1, $loading-color-2, $loading-color-2, $loading-color-1);

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@mixin has-loading-background {
  background: $loading-background;
  background-size: 300% 300%;
  animation: gradient 5s ease infinite;
}

@mixin has-table-border {
  &::after {
    content: " ";
    position: absolute;
    border-right: 1px solid #666666;
    right: 0px;
    top: $grid-gap * 0.5;
    bottom: $grid-gap * 0.5;
  }
}

@mixin has-dashboard-padding {
  padding: $grid-gap;
  padding-right: $grid-gap * 0.5;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin triangle($dir, $width, $height, $color) {
  border: 0 solid transparent;

  @if $dir == "T" {
    border-bottom-width: $height;
    border-right-width: $width * 0.5;
    border-left-width: $width * 0.5;
    border-bottom-color: $color;
  } @else if $dir == "B" {
    border-top-width: $height;
    border-right-width: $width * 0.5;
    border-left-width: $width * 0.5;
    border-top-color: $color;
  } @else if $dir == "R" {
    border-top-width: $width * 0.5;
    border-left-width: $height;
    border-bottom-width: $width * 0.5;
    border-left-color: $color;
  } @else if $dir == "L" {
    border-top-width: $height;
    border-right-width: $width;
    border-left-width: $width;
    border-top-color: $color;
    transform: rotate(90deg);
  }
}

@import "scss/variables.scss";

$notif-size: 12px;
.chatAvatar {
  transition: $default-transition;
  border: none;
  background: transparent;
  border-radius: $chat-icon-size * 0.5;
  width: $chat-icon-size;
  height: $chat-icon-size;
  cursor: pointer;
  position: relative;
  border: 2px solid white;
  &:hover {
    box-shadow: 0px 2px 4px rgba($primary-color, 0.65);
    transform: translateY(-2px);
    .unread {
      box-shadow: 0px 4px 4px rgba($primary-color, 0.65);
      transform: translateY(-4px);
    }
    &::after {
      box-shadow: 0px 4px 4px rgba($primary-color, 0.65);
      transform: translateY(-4px);
    }
  }
  &::after {
    position: absolute;
    content: " ";
    width: $notif-size;
    height: $notif-size;
    transition: $default-transition;
    border-radius: $notif-size * 0.5;
    top: 0px;
    right: 0px;
    background-color: $primary-color;
    border: 2px solid white;
  }
  &.online {
    &::after {
      background-color: $success-color;
    }
  }
  &.busy {
    &::after {
      background-color: $warning-color;
    }
  }
  &.offline {
    &::after {
      background-color: $danger-color;
      display: none;
    }
  }

  &.noNotif {
    &::after {
      display: none;
    }
  }

  .unread {
    position: absolute;
    transition: $default-transition;
    top: -5px;
    right: -5px;
    background-color: $danger-color;
    line-height: 1em;
    color: white;
    font-size: 12px;
    border: 2px solid white;
    font-weight: 700;
    padding: 3px 6px;
    border-radius: 9999999px;
  }

  img {
    border-radius: $chat-icon-size * 0.5;
    object-fit: cover;
  }
}

@import "scss/variables.scss";
@import "scss/mixin";

$nav-link-color: #ebeaef;

.sidebar {
  background: $primary-color;
  position: relative;
  color: $nav-link-color;
  width: 300px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  font-family: "Dosis", sans-serif;
  a {
    color: inherit;
    text-decoration: none;
  }
  header {
    display: flex;
    padding: 40px 47px;
    align-items: center;
    justify-content: center;
    .logo {
      height: 80px;
      object-fit: contain;
    }
  }
  nav.navigation {
    padding: 0px 20px 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;

    .icon {
      font-size: 30px;
      line-height: 30px;
    }
    a,
    button,
    .disabled {
      transition: background $default-transition;
      &.active {
        color: #00adb5;
        path {
          fill: #00adb5;
        }
      }
      padding: 16px 8px;
      font-size: 20px;
      font-weight: 600;
      color: inherit;
      text-transform: capitalize;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      flex-direction: column;
      &:hover {
        background: rgba(white, 0.2);
      }
    }

    .disabled {
      cursor: not-allowed;
      color: rgb(173, 173, 173);
      path {
        fill: rgb(173, 173, 173);
      }
    }
  }
  main.userCard {
    border-radius: 40px;
    overflow: visible;
    margin: auto 22px auto;
    padding: 25px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .name {
      color: #6578d0;
      margin-top: 1em;
      font-weight: 600;
      font-size: 24px;
    }
    .description {
      color: #b9b9bc;
      font-size: 20px;
      line-height: 26px;
    }

    .expand {
      transition: $default-transition;
      background: #0a838a;
      color: white;
      position: absolute;
      font-size: 16px;
      bottom: 0px;
      transform: translateY(50%);
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      &:hover {
        transform: translateY(50%) scale(1.1);
        background: lighten(#0a838a, 5);
      }
    }
  }
  footer {
    text-align: end;
    margin-top: auto;
    padding: 24px;
    font-size: 20px;
    button {
      border: none;
      background: transparent;
      color: white;
    }
    span {
      font-weight: 600;
      margin-left: 10px;
      font-family: "Dosis", sans-serif;
    }
  }

  &.closed {
    width: 100px;
    header {
      padding: 20px 30px;
    }
    nav.navigation {
      padding: 0px 20px 50px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 8px;
      .icon {
        font-size: 30px;
        line-height: 30px;
      }
      span {
        display: none;
      }
    }

    main.userCard {
      padding: 0px;
      background: transparent;
      .avatar {
        width: 50px;
        height: 50px;
        img {
          width: 50px;
          height: 50px;
        }
      }

      .name {
        display: none;
      }
      .description {
        display: none;
      }
    }
    footer {
      text-align: center;
      span {
        display: none;
      }
    }
    @media screen and (max-width: $screen-breakpoint) {
      width: 80px;

      nav.navigation {
        padding: 0px 15px 50px;
      }

      header {
        padding: 20px 15px;
      }
    }
  }

  .resize {
    position: absolute;
    background: transparent;
    width: 5px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    cursor: col-resize;
    transition: all 300ms ease-in;

    &:hover {
      background: rgba(white, 0.2);
    }
  }
}

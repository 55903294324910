.container {
  position: absolute;
  bottom: 40px;
  right: 50px;

  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: center;

  .spinner {
    width: 50px;
    height: 50px;
    background-color: #03aa6f;
    border-radius: 50%;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(17, 32, 49, 0.8);
    color: white;
    font-size: 0.8em;
    padding: 0 5px;
    line-height: 1.5em;
  }
}

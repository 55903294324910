@use "sass:math";

@import "scss/variables.scss";
@import "scss/mixin.scss";

$varients: (
  "primary": $primary-color,
  "secondary": $secondary-color,
  "danger": $danger-color,
  "success": $success-color,
);

$initial-step: -(36deg * 0.75);
$spinner-width: 20px;
$duration: 8s;

.placeHolder {
  border-radius: $card-radius;
  background: white;
  border: 5px dashed grey;
}
.card {
  border-radius: $card-radius;
  background: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-shrink: 1;
  color: $primary-color;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &.dragging {
    position: absolute;
    opacity: 0.2;
  }
  &.noShadow {
    box-shadow: unset;
  }

  @each $name, $color in $varients {
    &.border-#{$name} {
      border: 2px solid $color;
      box-shadow: 0px 4px 4px rgba($color, 0.25);
    }
  }

  &.noScroll {
    overflow: visible;
  }

  .cardHeader {
    &[draggable="true"] {
      cursor: move;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
    display: flex;
    justify-content: space-between;
    padding: ($card-padding * 0.5) $card-padding;

    @media screen and (max-width: $screen-breakpoint) {
      padding: 10px 10px;
    }
    align-items: center;
    font-size: 18px;
    h2 {
      font-family: "Dosis";
      font-style: normal;
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
    }

    &.noChildren {
      justify-content: flex-end;
    }
  }

  .tabsHeader {
    display: flex;
    align-items: center;
    font-size: 18px;
    position: sticky;
    top: 0px;
    isolation: isolate;
    z-index: 2;
    button {
      padding: ($card-padding * 0.5) 0px;
      width: 100%;
      border: 0px;
      border-bottom: 2px solid #ebeaef;
      font-family: "Dosis";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      background: white;
      text-align: center;
      color: #30475e;
      &:disabled {
        cursor: not-allowed;
        background: #ebeaef;
      }
      &.active {
        border-bottom-color: #00adb5;
      }
    }
  }

  .tabContainer {
    height: 100%;
    z-index: 1;
    isolation: isolate;
  }
  .cardBody {
    position: relative;
    height: auto;

    iframe {
      border: none;
    }

    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.h-100 {
      height: 100%;
    }
    padding: ($card-padding * 0.5) $card-padding;
    flex-grow: 1;
    &.noPadding {
      padding: 0px;
    }
    &.scroll {
      overflow: auto;

      background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
      background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
      background-repeat: no-repeat;
      background-color: white;
      background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

      background-attachment: local, local, scroll, scroll;
    }

    @media screen and (max-width: $screen-breakpoint) {
      padding: $sm-screen-card-padding;
    }
  }
}

@for $index from 0 to 10 {
  @keyframes spinner-rotate-#{$index} {
    0% {
      transform: rotate($initial-step * $index);
    }
    5% {
      transform: rotate(120deg + $initial-step * $index);
    }
    33% {
      transform: rotate(120deg + $initial-step * $index);
    }
    38% {
      transform: rotate(240deg + $initial-step * $index);
    }
    66% {
      transform: rotate(240deg + $initial-step * $index);
    }
    72% {
      transform: rotate(360deg + $initial-step * $index);
    }
    100% {
      transform: rotate(360deg + $initial-step * $index);
    }
  }
}

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loadingSvg {
    filter: drop-shadow(0px 5px 4px rgba($primary-color, 0.5));
    path {
      fill: none;
      stroke: #000000;
      transform-box: fill-box;
      right: (90px - $spinner-width) * 0.5;
      transform-origin: center 27px;
      @for $index from 1 to 10 {
        &:nth-of-type(#{$index}) {
          animation: $duration spinner-rotate-#{$index} linear infinite;
          transform: rotate($initial-step * $index);
          stroke: #59626f;
          animation-delay: math.div($duration, 27) * ($index - 1);
        }
      }
    }
  }
}
